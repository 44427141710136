// src/App.jsx
import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import LoadingScreen from './components/common/LoadingScreen'; // We'll create this

// Lazy load pages
const HomePage = React.lazy(() => import('./pages/HomePage'));
const GamePage = React.lazy(() => import('./pages/GamePage'));
const Leaderboard = React.lazy(() => import('./pages/Leaderboard'));
const ContactPage = React.lazy(() => import('./pages/Contact'));
const AboutPage = React.lazy(() => import('./pages/About'));
const PrivacyPage = React.lazy(() => import('./pages/Privacy'));
const TermsPage = React.lazy(() => import('./pages/Terms'));
const CookiePage = React.lazy(() => import('./pages/Cookie'));
const PlinkoPage = React.lazy(() => import('./pages/PlinkoPage'));

import './App.css'

function App() {
  return (
    <div className="app">
      <div className="stars"></div>
      <div className="app-content">
      <div className="twinkling"></div>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="play" element={<GamePage />} />
              <Route path="plinko" element={<PlinkoPage />} />
              <Route path="leaderboard" element={<Leaderboard />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="terms" element={<TermsPage />} />
              <Route path="cookie" element={<CookiePage />} />
              <Route path="privacy" element={<PrivacyPage />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;