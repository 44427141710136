// src/components/layout/Layout.jsx
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../utils/ScrollToTop'
import Navbar from './Navbar'
import Footer from './Footer'

function Layout() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <main className="main-content">
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout