// src/components/layout/Navbar.jsx
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, X, Volume2, VolumeX } from 'lucide-react'
import logo from '../../assets/logo.png'
import '../../styles/components/layout/Navbar.css'

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <Link to="/">
          <img src={logo} height={50}/>
        </Link>
      </div>

      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
          Home
        </Link>
        <Link to="/play" className={location.pathname === '/play' ? 'active' : ''}>
          Play Now
        </Link>
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>
          About
        </Link>
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>
          Contact
        </Link>
        <Link to="/leaderboard" className={location.pathname === '/leaderboard' ? 'active' : ''}>
          Leaderboard
        </Link>
      </div>

      <div className="nav-controls">
       
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <X /> : <Menu />}
        </button>
      </div>
    </nav>
  )
}

export default Navbar