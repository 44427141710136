// src/components/layout/Footer.jsx
import { Link } from 'react-router-dom'
import '../../styles/components/layout/Footer.css'
import UserConsent from '../common/UserConsent'

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>About Galactic Riches</h3>
            <p>Experience the ultimate social casino gaming adventure in space.</p>
          </div>

          <div className="footer-section">
            <h3>Quick Links</h3>
            <Link to="/about">About Us</Link>
            <Link to="/terms">Terms of Service</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/cookie">Cookie Policy</Link>
          </div>

          <div className="footer-section">
            <h3>Support</h3>
            <a href="mailto:info@galacticriches.com">info@galacticriches.com</a>
            <p>24/7 Customer Support</p>
          </div>
        </div>

        <div className="footer-disclaimer">
          <h4>Important Disclaimer</h4>
          <p>
            Galactic Riches is a unfastened-to-play social casino gaming platform designed in simple terms for enjoyment purposes. Our video games simulate gambling stories but do NOT provide actual money playing or possibilities to win actual money or prizes. The digital currency utilized in our video games ('Cosmic Credits' or comparable) has no real-global cost and cannot be cashed out for actual cash or exchanged for any tangible rewards.
          </p>
          <p>
            The video games are intended for an adult target audience elderly 18  (or criminal age for your jurisdiction). Success at social casino gaming does now not imply destiny achievement at actual money playing. Our games are designed to offer leisure and do now not constitute actual gambling mechanisms. Game results are decided by way of random quantity mills (RNGs) for leisure functions most effective.
          </p>
          <p>
            Practice Responsible Gaming: While Galactic Riches gives digital gaming studies most effective, we inspire responsible gaming habits. Set private time and spending limits for in-game purchases. If you or a person  is struggling with playing addiction, please contact the National Problem Gambling Helpline: 1-800-522-4700.
          </p>
          <p>
            Virtual Currency Notice: All digital gadgets, including however now not confined to credits, cash, tokens, and special capabilities, continue to be the assets of Galactic Riches and are certified, no longer sold, to users. We reserve the proper to modify, alter, manage, or cast off the virtual foreign money at any time with out be aware or liability.
          </p>
        </div>
        <div className="footer-legal">
          <p>
            Galactic Riches™ and all related games, content, and functions are emblems of GalacticRiches. All game symbols, characters, designs, and related intellectual assets are blanketed by means of international copyright and trademark laws. Unauthorized reproduction or distribution is precisely prohibited and might result in civil and criminal penalties.
          </p>
          <p>
            By gaining access to and using our offerings, you acknowledge which you have study, understood, and comply with our Terms of Service, Privacy Policy, and this disclaimer. We reserve the right to modify these terms at any time with out prior observe.
          </p>
        </div>

        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} Galactic Riches. All rights reserved.</p>
          <p>This is a social casino game. No real money gambling or real money prizes offered.</p>
          <p>For entertainment purposes only. Individual results may vary.</p>
        </div>
      </footer>

      <UserConsent />
    </>

  )
}

export default Footer