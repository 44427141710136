import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/layout/UserConsent.css'

const UserConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('galacticUserConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('galacticUserConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('galacticUserConsent', 'declined');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-content">
        <div className="cookie-main">
          <div className="cookie-header">
            <h3>Cookie Settings</h3>
            <button 
              className="cookie-toggle"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? 'Show Less' : 'Show More'}
            </button>
          </div>
          
          <p className="cookie-description">
            We use cookies to enhance your intergalactic gaming experience and analyze traffic to our space station. 
            These help us provide better service across the galaxy.
          </p>

          {showDetails && (
            <div className="cookie-details">
              <div className="cookie-category">
                <h4>Essential Cookies</h4>
                <p>Required for basic space navigation and security protocols.</p>
              </div>
              <div className="cookie-category">
                <h4>Analytics Cookies</h4>
                <p>Help us understand how cosmic travelers interact with our platform.</p>
              </div>
              <div className="cookie-category">
                <h4>Marketing Cookies</h4>
                <p>Used to deliver relevant promotional messages across the universe.</p>
              </div>
            </div>
          )}

          <div className="cookie-actions">
            <button 
              onClick={handleAccept}
              className="cookie-button accept"
            >
              Accept All Cookies
            </button>
            <button 
              onClick={handleDecline}
              className="cookie-button decline"
            >
              Essential Only
            </button>
          </div>

          <div className="cookie-footer">
            <Link to="/cookie" className="cookie-link">Cookie Policy</Link>
            <Link to="/privacy" className="cookie-link">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserConsent;